/* global $ */
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { TransitionGroup, Transition } from "react-transition-group";
import { play, exit } from "./timelines";

import { ParallaxProvider } from "react-scroll-parallax";
import ScrollToTop from "./components/layout/ScrollToTop";

import "./App.css";
import Header from "./components/layout/Header";
import Pocetna from "./components/layout/Pocetna";
import Footer from "./components/layout/Footer";
import Usluge from "./components/layout/Usluge";
import About from "./components/layout/About";
import Reference from "./components/layout/Reference";
import Kontakt from "./components/layout/Kontakt";

class App extends Component {
  componentDidMount() {
    // MOJ JS

    $(".gallery a").click(function(e) {
      // Special stuff to do when this link is clicked...

      // Cancel the default action
      e.preventDefault();
    });
    $(window).scroll(function() {
      if ($(".navbar").offset().top > 50) {
        $(".navbar").addClass("scrolled");
      } else {
        $(".navbar").removeClass("scrolled");
      }
    });

    // Select Dom items
    const menuBtn = document.querySelector(".menu-btn");
    const menu = document.querySelector(".mob-menu");
    const menuNav = document.querySelector(".mob-menu-nav");
    const menuBranding = document.querySelector(".mob-menu-branding");
    const navbarLogo = document.querySelector(".logo");
    const menuNavbar = document.querySelector(".navbar");

    const navItems = document.querySelectorAll(".mob-menu-nav-item");

    //Set initial state of the menu
    let showMenu = false;

    menuBtn.addEventListener("click", toggleMenu);
    navItems.forEach((item) => item.addEventListener("click", toggleMenu));

    function toggleMenu() {
      if (!showMenu) {
        menuBtn.classList.add("close");
        menu.classList.add("show");
        menuNav.classList.add("show");
        menuBranding.classList.add("show");
        navbarLogo.classList.add("hide");
        menuNavbar.classList.add("hide-background");

        navItems.forEach((item) => item.classList.add("show"));
        //Set  state of the menu
        showMenu = true;
      } else {
        menuBtn.classList.remove("close");
        menu.classList.remove("show");
        menuNav.classList.remove("show");
        menuBranding.classList.remove("show");
        navbarLogo.classList.remove("hide");
        menuNavbar.classList.remove("hide-background");
        navItems.forEach((item) => item.classList.remove("show"));
        //Set  state of the menu
        showMenu = false;
      }
    }
  }

  render() {
    return (
      <Router>
        <div className="App">
          <ParallaxProvider>
            <Header />
            <Route
              render={({ location }) => {
                const { pathname, key } = location;
                return (
                  <TransitionGroup component={null}>
                    <Transition
                      key={key}
                      appear={true}
                      onEnter={(node, appears) => play(pathname, node, appears)}
                      onExit={(node, appears) => exit(node, appears)}
                      timeout={{ enter: 750, exit: 150 }}
                    >
                      <Switch location={location}>
                        <Route exact path="/" component={Pocetna} />
                        <Route exact path="/usluge" component={Usluge} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/reference" component={Reference} />
                        <Route exact path="/kontakt" component={Kontakt} />
                      </Switch>
                    </Transition>
                  </TransitionGroup>
                );
              }}
            />
            <Footer />
          </ParallaxProvider>
        </div>

        <ScrollToTop />
      </Router>
    );
  }
}

export default App;
