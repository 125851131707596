import React, { Component } from "react";
import GoogleMapComponentWithMarker from "./GoogleMapComponentWithMarker";

import Form from "./Form";

/**

 * @config prepareing config prop.

 * api: url for the server endpoint

 * title: form title

 * successMessage: message will show in the UI when mail is successfully sent.

 * errorMessage: message will show in the UI when mail is not sent.

 * fields: this is the name of each field. This should be exact order of the fieldsConfig and fieldsConfig.fieldName should be  the same

 * fieldsConfig = settings for each input/textarea field

 */

export default class Kontakt extends Component {
  componentDidMount() {}

  render() {
    const config = {
      api: `${process.env.REACT_APP_API}`,
      title: "Contact Me",
      successMessage: "Vaš upit je poslan, hvala vam na povjerenju.",
      errorMessage: "Došlo je do poteškoća, poruka nije poslana.",
      fieldsConfig: [
        {
          id: 1,
          label: "Ime",
          fieldName: "ime",
          type: "text",
          placeholder: "Ime i prezime",
          isRequired: true,
          klassName: "ime-field",
          spanName: "spanIme"
        },
        {
          id: 2,
          label: "Email",
          fieldName: "email",
          type: "email",
          placeholder: "Vaš Email",
          isRequired: true,
          klassName: "email-field",
          spanName: "spanEmail"
        },
        {
          id: 3,
          label: "Tel",
          fieldName: "tel",
          type: "text",
          placeholder: "Broj za kontakt",
          isRequired: true,
          klassName: "tel-field",
          spanName: "spanTel"
        },
        {
          id: 4,
          label: "Poruka",
          fieldName: "poruka",
          type: "textarea",
          placeholder: "Vaš upit...",
          isRequired: true,
          klassName: "poruka-field",
          spanName: "spanPoruka"
        }
      ]
    };

    return (
      <section className="kontakt section-main">
        <section className="kontakt-a">
          <div className="container">
            <div className="decor-circle-parallax" />

            <div className="kontakt-opis">
              <h2>kontaktiraj nas</h2>

              <p>
                Ukoliko su vam potrebne dodatne informacije o nama ili ste
                zainteresirani za naše usluge, molimo vas da ispunite kontaktni
                obrazac i javit ćemo vam se u što kraćem roku. Vaš upit će biti
                detaljno i preofesionalno razmotren kako bismo postigli
                obostrano zadovoljstvo.
              </p>
            </div>
            <Form config={config} />
          </div>
        </section>
        <section className="kontakt-b">
          <div className="map">
            <GoogleMapComponentWithMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC31g5tGBAKxYRk-R7roY_wTrisOaKlQIc"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
          <div className="info-box">
            <div>
              <h2>gdje smo</h2>
              <br />
              <h4>Pinus Point d.o.o.</h4>
              <h4>Lug 39</h4>
              <h4>21264 Donji Proložac</h4>
              <h4>Mob: 098 448 008</h4>
              <h4>email: pinus.pointdoo@gmail.com</h4>
              <h4>OIB: 45221226795</h4>
              <br />
              <h3>ured</h3>
              <h4>Fra Rajmonda Rudeža 1</h4>
              <h4>21260 Imotski</h4>
            </div>
          </div>
        </section>
      </section>
    );
  }
}
