import { TimelineMax as Timeline, Power1 } from "gsap";

const getDefaultTimeline = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  const loader = document.querySelector(".loader");

  timeline
    .from(node, 0.5, {
      autoAlpha: 0,
      ease: Power1.easeInOut
    })
    .to(loader, 0.5, {
      autoAlpha: 0,

      ease: Power1.easeInOut
    });

  return timeline;
};

export const play = (pathname, node, appears) => {
  const delay = appears ? 0 : 0.5;
  let timeline;

  timeline = getDefaultTimeline(node, delay);

  window.loadPromise.then(() => timeline.play());
};

export const exit = node => {
  const timeline = new Timeline({ paused: true });
  const loader = document.querySelector(".loader");

  timeline
    .to(loader, 0.5, {
      autoAlpha: 100,
      ease: Power1.easeInOut
    })
    .to(node, 1, {
      display: "none",
      autoAlpha: 0,
      ease: Power1.easeInOut
    });

  timeline.play();
};
