import React from "react";
import { withController } from "react-scroll-parallax";

import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade"; //https://www.react-reveal.com/docs/
import { Parallax } from "react-scroll-parallax";

import about_slika from "../imgs/about_slika.jpg";

function About() {
  return (
    <section className="about section-main">
      <section className="about-a">
        <div className="container">
          <div className="about-slika">
            <Fade bottom duration={1000}>
              <img src={about_slika} alt="" />
            </Fade>
          </div>
          <Parallax className="decor-circle-parallax-banner" y={[0, 100]}>
            <div className="decor-circle-parallax" />
          </Parallax>
          <div className="about-opis">
            <h2>o nama</h2>

            <p>
              Tvrtka PINUS POINT osnovana je 1997.g. Jamstvo kvalitete usluge je
              dugogodišnja uspješna suradnja sa općinama i hotelima Makarske
              rivijere kojima pružamo cjelogodišnje usluge uređenja i održavanja
              zelenih površina. <br></br>
              <br></br>Naše usluge uključuju; građevinske radove u području
              niskogradnje, izgradnju cesta, ulica, trgova i ostalih prometnih
              objekata, izgradnju vodovoda i kanalizacije, šumarstvo i sječu
              drva, uređenje zelenih površina i izvođenje radova, izradu
              krajobraznih projekata svih nivoa složenosti, cjelogodišnje
              održavanje, nadzor te izradu projekata navodnjavanja i postavu
              sistema navodnjavanja.<br></br>
              <br></br>Osnivač tvrtke je Nenad Bubalo. Tvrtka zapošljava
              visokoobrazovne kadrove iz područja građevinarstva, šumarstva i
              hortikulture. S klijentima blisko surađujemo kako bi dobili što
              bolji uvid u njihove potrebe i želje te detaljno upoznali
              predmetne lokacije. Projektiranju i izvođenju pristupamo tek nakon
              temeljitih analiza, s ciljem postizanja maksimalne najveće
              održivosti. Visoka stručna kompetencija, znanje i iskustvo
              okrenuto budućnosti i izvrsna interna infrastruktura omogućuju nam
              da ispunimo i one najzahtjevnije želje naših kupaca-sigurno,
              ekonomično, brzo i fleksibilno.
            </p>
          </div>
        </div>
      </section>
      <Fade bottom duration={1000}>
        <section className="about-b">
          <div className="container">
            <div className="blok">
              <h2>kontaktirajte nas i zajedno pristupimo vašem projektu </h2>
              <NavLink className="btn" to="/kontakt">
                kontakt
              </NavLink>
            </div>
          </div>
        </section>
      </Fade>
    </section>
  );
}

export default withController(About);
