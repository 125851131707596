import React, { Component } from "react";

import Images from "./Images";

import Fade from "react-reveal/Fade"; //https://www.react-reveal.com/docs/

import referencePopis from "../../files/Reference.pdf";
import katalog from "../../files/Katalog.pdf";

export default class Reference extends Component {
  componentDidMount() {}

  render() {
    return (
      <section className="reference section-main">
        <section className="reference-a">
          <div className="container">
            <div className="reference-opis">
              <h2>reference</h2>
              <h3>izvođenje hortikulturnih radova i radova niskogradnje</h3>
            </div>
            <div>
              <p>
                -Projektiranje i krajobrazno uređenje okoliša s projektom
                navodnjavanja,Hotel Medora Auri, Podgora - Naručitelj: MEDORA
                HOTELI I LJETOVALIŠTA d.d.<br></br>
                -Projektiranje i krajobrazno uređenje arla s projektom
                navodnjavanja, Grand Hotel Slavia, Baška Voda - Naručitelj:
                HOTELI BAŠKA VODA d.d.<br></br>
                -Projektiranje i krajobrazno uređenje – zelene površine i
                parkinzi, LIDL poslovnica Ploče - Naručitelj: LIDL Hrvatska
                d.o.o.<br></br>
                -Krajobrazno uređenje i godišnje održavanje zelenih površina
                općine Gradac - Naručitelj: Općina Gradac<br></br>
                -Projektiranje i krajobrazno uređenje - zelene površine s
                projektom navodnjavanja, te postavljanjem betonskih opločnika,
                Kamp Galeb, Omiš - Naručitelj: Galeb d.d.<br></br>
                -Krajobrazno uređenje zelenih površina i izrada projekta
                navodnjavanja – Naručitelj: Osnovna škola Pazdigrad, Split
                <br></br>
                -Krajobrazno uređenje, zelene površine - Granični prijelaz Mali
                Prolog<br></br>
                -Projektiranje i krajobrazno uređenje zeleni otoci i kružni tok
                s projektom navodnjavanja. Rekonstrukcija raskrižja državnih
                cesta DC1 i DC216; Sinj<br></br>
                -Projektiranje i krajobrazno uređenje šetnice s projektom
                navodnjavanja – Naručitelj: Općina Podgora<br></br>
                -Krajobrazno uređenje, zelene površine i izrada projekta
                navodnjavanja Zapadna obala, Split<br></br>
                -Izgradnja parkirališta, priključne prometnice na županijsku
                cestu, te krajobrazno uređenje - uređenje trgova oko visećeg
                mosta preko rijeke Cetine, zapadni ulaz na most s priključkom na
                županijsku cestu ŽC 6148 – Naručitelj: Grad Trilj<br></br>
                -Izgradnja i uređenje križanja ˝Čaklje˝ na državnoj cesti DC8 –
                Podgora<br></br>
                -Radovi na izmjeni vodoopskrbnog cjevovoda, Makarska rivijera –
                Naručitelj: Vodovod Makarska d.o.o.<br></br>
                -Krajobrazno uređenje, zelene površine sa uključenim
                navodnjavanjem - Luka Gaženica; Zadar<br></br>
                -Krajobrazno uređenje, zelene površine - Granični prijelaza Orah
                <br></br>
                -Krajobrazno uređenje, zelene površine s projektom na
                navodnjavanje - Naručitelj: Grad Makarska<br></br>
                -Projektiranje i krajobrazno uređenje zelene površine na ulazu u
                Jezera s projektom navodnjavanja – Naručitelj: Općina Tisno
                <br></br>
                -Krajobrazno uređenje, zeleni pojasevi Lučka cesta C1-B – Lučka
                uprava Ploče<br></br>
                -Krajobrazno uređenje zelenih pojaseva Luka Rogač – Šolta
                <br></br>
                -Projektiranje i krajobrazno uređenje, zelene površine i
                parkinzi LIDL poslovnica Vodice Naručitelj: LIDL Hrvatska d.o.o.
                <br></br>
                -Projektiranje i krajobrazno uređenje, zelene površine i
                parkinzi LIDL poslovnica Split Spinut – Naručitelj: LIDL
                Hrvatska d.o.o.<br></br>
                -Krajobrazno uređenje okoliša Viktoria hoteli, Seget Donji –
                Naručitelj: VIKTORIA HOTELI d.o.o.<br></br>
                -Krajobrazno uređenje okoliša, Split – Naručitelj: Škola za
                dizajn, grafiku i održivu gradnju<br></br>
                -Izgradnja dijela D120, obilaznica naselja Polače – otok Mljet
                <br></br>
                -Projektiranje i krajobrazno uređenje okoliša i interijera –
                Hotel Dalmacija; Makarska – Naručitelj: Hoteli Makarska d.d.
                <br></br>
              </p>
              <h3>održavanje</h3>
              <p>
                -Krajobrazno uređenje i godišnje održavanje zelenih površina
                općine Baška Voda – Naručitelj: Općina Baška Voda<br></br>
                -Krajobrazno uređenje i godišnje održavanje zelenih površina
                LIDL poslovnica – Naručitelj: LIDL d.o.o.<br></br>
                -Godišnje održavanje zelenih površina luke Gaženica<br></br>
                -Godišnje održavanje zelenih otoka i srednjeg razdjelnog pojasa
                na dionici D8 (Kaštel Gomilica – Kaštel Stari)<br></br>
                -Godišnje održavanje zelenih površina hotela Medora Auri –
                Naručitelj: Medora Hoteli i Ljetovališta d.d.<br></br>
                -Godišnje održavanje zelenih površina hotela Viktoria –
                Naručitelj: Viktoria Hoteli d.o.o.<br></br>
                -Krajobrazno uređenje i godišnje održavanje zelenih površina
                općine Podgora – Naručitelj: Općina Podgora<br></br>
                -Krajobrazno uređenje i godišnje održavanje zelenih površina
                općine Marina – Naručitelj: Općina Marina<br></br>
                -Krajobrazno uređenje i godišnje održavanje zelenih površina
                općine Gradac – Naručitelj: Općina Gradac<br></br>
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={referencePopis}
                className="btn"
              >
                vidi sve reference
              </a>
            </div>
          </div>
        </section>
        <Fade bottom duration={1000}>
          <section className="reference-b">
            <div className="container">
              <div className="blok">
                <h2>pregledajte naš katalog</h2>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={katalog}
                  className="btn"
                >
                  Pinus Point katalog
                </a>
              </div>
            </div>
          </section>
        </Fade>
        <section className="reference-c">
          <div className="container">
            <Images />
          </div>
        </section>
      </section>
    );
  }
}
