import React, { useState } from "react";
import { Spring } from "react-spring/renderprops";
import * as easings from "d3-ease";
import FsLightbox from "fslightbox-react";
import { Parallax } from "react-scroll-parallax";
import Fade from "react-reveal/Fade"; //https://www.react-reveal.com/docs/
import { NavLink } from "react-router-dom";

import Trava from "../svgs/Trava.js";
import Cross from "../svgs/Cross.js";

import pocetna2 from "../imgs/PinusPoint_pocetna_2.jpg";
import pocetna3 from "../imgs/PinusPoint_pocetna_3.jpg";
import pocetna4 from "../imgs/PinusPoint_pocetna_4.jpg";

import izdvojeno1 from "../imgs/izdvojeno/izdvojeno_1.jpg";
import izdvojeno2 from "../imgs/izdvojeno/izdvojeno_2.jpg";
import izdvojeno3 from "../imgs/izdvojeno/izdvojeno_3.jpg";
import izdvojeno4 from "../imgs/izdvojeno/izdvojeno_4.jpg";
import izdvojeno5 from "../imgs/izdvojeno/izdvojeno_5.jpg";
import izdvojeno6 from "../imgs/izdvojeno/izdvojeno_6.jpg";

import thumb1 from "../imgs/izdvojeno/thumb1.jpg";
import thumb2 from "../imgs/izdvojeno/thumb2.jpg";
import thumb3 from "../imgs/izdvojeno/thumb3.jpg";
import thumb4 from "../imgs/izdvojeno/thumb4.jpg";
import thumb5 from "../imgs/izdvojeno/thumb5.jpg";
import thumb6 from "../imgs/izdvojeno/thumb6.jpg";

function Pocetna() {
  const izvori = [
    izdvojeno1,
    izdvojeno2,
    izdvojeno3,
    izdvojeno4,
    izdvojeno5,
    izdvojeno6,
  ];
  const thumbnails = [thumb1, thumb2, thumb3, thumb4, thumb5, thumb6];

  const year = new Date().getFullYear();

  const [godineAnim, postaviGodineAnim] = useState({
    reseter: false,
  });
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function pokreniGodine() {
    postaviGodineAnim({
      reseter: true,
    });
  }

  function openLightboxOnSlide(e, number) {
    e.preventDefault();
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <section className="pocetna">
      <Parallax className="parallax-header-trava" y={[0, 150]}>
        <Trava className="header-trava" />
      </Parallax>
      <section className="pocetna-a">
        <div className="container">
          <div />
          <div>
            <h1 className="lg-heading">
              PINUS
              <br />
              <span className="secondary-txt">POINT</span>
            </h1>
            <h3 className="sm-heading">Za hortikulturu i krajobraz</h3>
          </div>
          <div />
          <div className="decor-circle" />
        </div>
      </section>
      <section className="pocetna-b">
        <div className="container">
          <div className="content-box">
            <Fade top duration={1000}>
              <h2>vaš partner u izvođenju i održavanju</h2>
              <p>
                Sa širokim rasponom djelatnosti s vama smo kroz cijeli proces,
                od same izrade krajobraznih i hortikulturnih projekta, preko
                izvođenja do održavanja.
                <br />
                <br />
                Projektiranju i izvođenju pristupamo tek nakon temeljitih
                analiza uzimajući u obzir potrebe i želje kupaca i zahtjeve
                predmetnih lokacija, s ciljem postizanja visoke kvalitete i
                maksimalne održivosti.
              </p>
              <NavLink className="btn" to="/usluge">
                naše usluge
              </NavLink>
            </Fade>
            <Parallax className="parallax-cross1" y={[-50, 800]}>
              <Cross className="cross1" />
            </Parallax>
          </div>
          <div>
            <Parallax className="img-square slikaR" y={[0, -15]}>
              <img src={pocetna2} alt="" />
            </Parallax>
            <Parallax className="img-squareW slikaB" y={[0, -70]}>
              <img src={pocetna3} alt="" />
            </Parallax>
          </div>
          <div className="kvaliteta-slika">
            <img className="img-squareH slikaB" src={pocetna4} alt="" />

            <Fade
              bottom
              duration={1200}
              delay={500}
              onReveal={() => pokreniGodine()}
            >
              <div className="godine">
                <Spring
                  from={{ broj: 0 }}
                  to={{ broj: year - 1997 }}
                  config={{
                    duration: 2100,
                    easing: easings.easeCircleOut,
                  }}
                  reset={godineAnim.reseter}
                  delay={500}
                >
                  {(props) => <h1>{props.broj.toFixed()}</h1>}
                </Spring>
                <p>godine iskustva</p>
                <div className="krug" />
              </div>
            </Fade>
          </div>
          <div className="kvaliteta">
            <Fade top duration={1000}>
              <h2>kvaliteta zajamčena iskustvom i stručnim kadrom</h2>
              <p>
                Jamstvo kvalitete usluge su iskustvo stečeno od osnutka firme
                Pinus Point 1997 godine i dugogodišnja uspješna suradnja sa
                općinama i hotelima Makarske Rivijere te Imotske krajine
                <br />
                <br />
                Dodatan jamac kvalitete je visoka stručna kompetencija, znanje i
                iskustvo okrenuto budućnosti te izvrsna interna infrastruktura.
              </p>
              <NavLink className="btn" to="/about">
                više o Pinus Pointu
              </NavLink>
            </Fade>
            <Parallax className="parallax-cross2" y={[-200, 1000]}>
              <Cross className="cross2" />
            </Parallax>
          </div>
        </div>
      </section>
      <section className="pocetna-c">
        <Parallax className="parallax-footer-trava" y={[-230, 220]}>
          <Trava className="footer-trava" />
        </Parallax>
        <div className="container">
          <NavLink className="btn" to="/reference">
            pogledaj naše projekte
          </NavLink>
          <h2 className="pocetna-naslov1">izdvojeno</h2>
        </div>
        <div className="izdvojeno gallery">
          {thumbnails.map((thumb, indeks) => (
            <a
              key={indeks}
              href="/"
              onClick={(e) => openLightboxOnSlide(e, indeks + 1)}
            >
              <img src={thumb} alt="" />
            </a>
          ))}
        </div>

        <FsLightbox
          toggler={lightboxController.toggler}
          slide={lightboxController.slide}
          sources={izvori}
        />
      </section>
    </section>
  );
}

export default Pocetna;
