import React, { Component } from "react";
import { withController } from "react-scroll-parallax";
import PropTypes from "prop-types";

class Footer extends Component {
  static propTypes = {
    parallaxController: PropTypes.object,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.props.parallaxController.update();
    }
  }

  render() {
    return (
      <footer className="section-footer">
        <div className="container">
          <div>
            <p className="footer-info">
              Pinus Point d.o.o.
              <br />
              Lug 39, Donji Proložac 21264
              <br />
              Mob: 098 448 008
              <br />
              email: pinus.pointdoo@gmail.com
              <br />
              OIB: 45221226795
              <br />
              Vl. Nenad Bubalo
            </p>
          </div>
          <div>
            <h2>PINUS POINT</h2>
            <p className="footer-opis">
              za hortikulturu, navodnjavanje, krajobrazno uređenje, šumarstvo,
              održavanje i niskogradnju
            </p>
          </div>

          <div>
            <p className="copyright">
              © Pinus Point {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
export default withController(Footer);
