import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

/**

 * @component Form

 * @props - { object } -  config

 */

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailSent: false,
      error: null,
      ime: "",
      email: "",
      tel: "",
      poruka: ""
    };
  }

  /**

  * @function handleFormSubmit
  * @param e { obj } - form event
  * @return void
  */

  handleFormSubmit = e => {
    e.preventDefault();

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}`,
      headers: { "content-type": "application/json; charset=utf-8" },
      data: this.state
    })
      .then(response => {
        if (response.data.sent) {
          this.setState({
            mailSent: response.data.sent
          });
          this.setState({ error: false });
        } else {
          this.setState({ error: true });
        }
      })
      .catch(error => this.setState({ error: error.message }));
  };
  /**
   * @function handleChange
   * @param e { obj } - change event
   * @param field { string } - name of the field
   * @return void
   */

  handleChange = (e, field) => {
    this.setState({ [field]: e.target.value });
  };

  inputFocus = stanje => e => {
    this.setState({ ["Focus" + e.target.name]: stanje });
  };

  render() {
    const { successMessage, errorMessage, fieldsConfig } = this.props.config;

    return (
      <div className="kontakt-form">
        <form action="#">
          {fieldsConfig &&
            fieldsConfig.map(field => {
              // automatsko renderiranje

              return (
                <React.Fragment key={field.id}>
                  {field.type !== "textarea" ? ( // Provjera je li input field ili text area
                    <React.Fragment>
                      <span
                        className={`${
                          this.state["Focus" + field.fieldName] ? "focused" : ""
                        }`}
                      >
                        <input
                          type={field.type}
                          className={field.klassName}
                          placeholder={field.placeholder}
                          value={field.vrijednost}
                          onChange={e => this.handleChange(e, field.fieldName)}
                          onFocus={this.inputFocus(true)}
                          onBlur={this.inputFocus(false)}
                          name={field.fieldName}
                        />
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span
                        className={`${
                          this.state["Focus" + field.fieldName] ? "focused" : ""
                        }`}
                      >
                        <textarea
                          rows="4"
                          className={field.klassName}
                          placeholder={field.placeholder}
                          onChange={e => this.handleChange(e, field.fieldName)}
                          value={field.name}
                          onFocus={this.inputFocus(true)}
                          onBlur={this.inputFocus(false)}
                          name={field.fieldName}
                        />
                      </span>
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            })}

          <button
            type="submit"
            className="btn"
            onClick={e => this.handleFormSubmit(e)}
          >
            pošalji
          </button>

          <div>
            {this.state.mailSent && (
              <div className="sucsess">{successMessage}</div>
            )}

            {this.state.error && <div className="error">{errorMessage}</div>}
          </div>
        </form>
      </div>
    );
  }
}

export default Form;

//propTypes for the component

Form.propTypes = {
  config: PropTypes.object.isRequired
};
