import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <header>
        <div className="loader">
          <div className="pinus-container">
            <div className="pinus1">
              <div className="pinus-line" />
              <div className="pinus-line" />
              <div className="pinus-line" />
            </div>
            <div className="pinus2">
              <div className="pinus-line" />
              <div className="pinus-line" />
              <div className="pinus-line" />
            </div>
          </div>
        </div>

        <nav className="navbar">
          <div className="container">
            <h1 className="logo">Pinus Point</h1>
            <ul className="nav">
              <li className="nav-item">
                <NavLink to="/" exact>
                  Naslovnica
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/usluge">Usluge</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/reference">Reference</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about">O nama</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/kontakt">Kontakt</NavLink>
              </li>
            </ul>
            <div className="menu-btn-container">
              <div className="menu-btn">
                <div className="btn-line" />
                <div className="btn-line" />
                <div className="btn-line" />
              </div>
            </div>
          </div>
        </nav>
        <nav className="mob-menu">
          <div className="mob-menu-branding">
            <h2 className="logo">Pinus Point</h2>
          </div>
          <ul className="mob-menu-nav">
            <li className="mob-menu-nav-item">
              <NavLink to="/" className="mob-nav-link" exact>
                Naslovnica
              </NavLink>
            </li>
            <li className="mob-menu-nav-item">
              <NavLink to="/usluge" className="mob-nav-link">
                Usluge
              </NavLink>
            </li>
            <li className="mob-menu-nav-item">
              <NavLink to="/reference" className="mob-nav-link">
                Reference
              </NavLink>
            </li>
            <li className="mob-menu-nav-item">
              <NavLink to="/about" className="mob-nav-link">
                O nama
              </NavLink>
            </li>
            <li className="mob-menu-nav-item">
              <NavLink to="/kontakt" className="mob-nav-link">
                Kontakt
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default Header;
