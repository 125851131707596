import React from "react";

const SvgTrava = props => (
  <svg viewBox="0 0 424 120" {...props}>
    <defs>
      <clipPath id="trava_svg__clip-path">
        <path fill="none" d="M0 0h424v120.412H0z" />
      </clipPath>
      <style>
        {
          ".trava_svg__cls-3,.trava_svg__cls-4{fill:none;stroke:#fff;stroke-linecap:round;stroke-width:3px}.trava_svg__cls-3{stroke-linejoin:round}"
        }
      </style>
    </defs>
    <g
      id="trava_svg__Repeat_Grid_10"
      data-name="Repeat Grid 10"
      opacity={0.46}
      clipPath="url(#trava_svg__clip-path)"
    >
      <g
        id="trava_svg__Group_13"
        data-name="Group 13"
        transform="translate(-1089.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-2"
        data-name="Group 13"
        transform="translate(-1053.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4-2"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-2"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-3"
        data-name="Group 13"
        transform="translate(-1017.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4-3"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-3"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-4"
        data-name="Group 13"
        transform="translate(-981.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4-4"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-4"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-5"
        data-name="Group 13"
        transform="translate(-945.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4-5"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-5"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-6"
        data-name="Group 13"
        transform="translate(-909.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4-6"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-6"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-7"
        data-name="Group 13"
        transform="translate(-873.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4-7"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-7"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-8"
        data-name="Group 13"
        transform="translate(-837.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4-8"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-8"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-9"
        data-name="Group 13"
        transform="translate(-801.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4-9"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-9"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-10"
        data-name="Group 13"
        transform="translate(-765.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4-10"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-10"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-11"
        data-name="Group 13"
        transform="translate(-729.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4-11"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-11"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-12"
        data-name="Group 13"
        transform="translate(-693.329 -2832.499)"
      >
        <path
          id="trava_svg__Path_4-12"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-12"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-13"
        data-name="Group 13"
        transform="translate(-1089.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-13"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-13"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-14"
        data-name="Group 13"
        transform="translate(-1053.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-14"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-14"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-15"
        data-name="Group 13"
        transform="translate(-1017.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-15"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-15"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-16"
        data-name="Group 13"
        transform="translate(-981.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-16"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-16"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-17"
        data-name="Group 13"
        transform="translate(-945.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-17"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-17"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-18"
        data-name="Group 13"
        transform="translate(-909.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-18"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-18"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-19"
        data-name="Group 13"
        transform="translate(-873.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-19"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-19"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-20"
        data-name="Group 13"
        transform="translate(-837.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-20"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-20"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-21"
        data-name="Group 13"
        transform="translate(-801.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-21"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-21"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-22"
        data-name="Group 13"
        transform="translate(-765.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-22"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-22"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-23"
        data-name="Group 13"
        transform="translate(-729.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-23"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-23"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-24"
        data-name="Group 13"
        transform="translate(-693.329 -2800.499)"
      >
        <path
          id="trava_svg__Path_4-24"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-24"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-25"
        data-name="Group 13"
        transform="translate(-1089.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-25"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-25"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-26"
        data-name="Group 13"
        transform="translate(-1053.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-26"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-26"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-27"
        data-name="Group 13"
        transform="translate(-1017.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-27"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-27"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-28"
        data-name="Group 13"
        transform="translate(-981.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-28"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-28"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-29"
        data-name="Group 13"
        transform="translate(-945.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-29"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-29"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-30"
        data-name="Group 13"
        transform="translate(-909.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-30"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-30"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-31"
        data-name="Group 13"
        transform="translate(-873.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-31"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-31"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-32"
        data-name="Group 13"
        transform="translate(-837.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-32"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-32"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-33"
        data-name="Group 13"
        transform="translate(-801.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-33"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-33"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-34"
        data-name="Group 13"
        transform="translate(-765.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-34"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-34"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-35"
        data-name="Group 13"
        transform="translate(-729.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-35"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-35"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-36"
        data-name="Group 13"
        transform="translate(-693.329 -2768.499)"
      >
        <path
          id="trava_svg__Path_4-36"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-36"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-37"
        data-name="Group 13"
        transform="translate(-1089.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-37"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-37"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-38"
        data-name="Group 13"
        transform="translate(-1053.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-38"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-38"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-39"
        data-name="Group 13"
        transform="translate(-1017.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-39"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-39"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-40"
        data-name="Group 13"
        transform="translate(-981.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-40"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-40"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-41"
        data-name="Group 13"
        transform="translate(-945.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-41"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-41"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-42"
        data-name="Group 13"
        transform="translate(-909.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-42"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-42"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-43"
        data-name="Group 13"
        transform="translate(-873.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-43"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-43"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-44"
        data-name="Group 13"
        transform="translate(-837.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-44"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-44"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-45"
        data-name="Group 13"
        transform="translate(-801.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-45"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-45"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-46"
        data-name="Group 13"
        transform="translate(-765.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-46"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-46"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-47"
        data-name="Group 13"
        transform="translate(-729.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-47"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-47"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
      <g
        id="trava_svg__Group_13-48"
        data-name="Group 13"
        transform="translate(-693.329 -2736.499)"
      >
        <path
          id="trava_svg__Path_4-48"
          data-name="Path 4"
          className="trava_svg__cls-3"
          d="M0 8.97l5.235-5.434L8.641 0l8.12 8.97"
          transform="rotate(180 554.045 1423.559)"
        />
        <path
          id="trava_svg__Line_7-48"
          data-name="Line 7"
          className="trava_svg__cls-4"
          transform="translate(1099.492 2834.5)"
          d="M0 12.625L.008 0"
        />
      </g>
    </g>
  </svg>
);

export default SvgTrava;
