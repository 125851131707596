// GoogleMapWithMarker.jsx

// Import React
import * as React from "react";

// Import necessary components for React Google Maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import logo from "./PinusPoint.png";

// Import custom styles to customize the style of Google Map
const styles = require("./GoogleMapStyles.json");

// Import custom icon for map marker
// You can use this if you need to support IE11 and lower.
// const mapMarker = require('./GoogleMapMarker.svg')

// Google Map component
const GoogleMapComponentWithMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{
        lat: 43.447348, // latitude for the center of the map
        lng: 17.220162 // longitude for the center of the map
      }}
      defaultOptions={{
        disableDefaultUI: true, // disable default map UI
        draggable: true, // make map draggable
        keyboardShortcuts: false, // disable keyboard shortcuts
        scaleControl: true, // allow scale controle
        //scrollwheel: true, // allow scroll wheel
        styles: styles, // change default map styles
        gestureHandling: "cooperative"
      }}
    >
      <Marker
        icon={{
          url: logo
        }}
        position={{
          lat: 43.447348, // latitude to position the marker
          lng: 17.220162 // longitude to position the marker
        }}
      ></Marker>
    </GoogleMap>
  ))
);

// Export Google Map component
export default GoogleMapComponentWithMarker;
