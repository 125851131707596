import React from "react";

const SvgCross = props => (
  <svg viewBox="0 0 51 51" {...props}>
    <g
      data-name="Group 5"
      fill="none"
      stroke="#9fedd7"
      strokeLinecap="round"
      strokeWidth={2}
    >
      <g
        data-name="Ellipse 5"
        transform="translate(12.447 12.447)"
        strokeLinejoin="round"
      >
        <circle cx={13.392} cy={13.392} r={13.392} stroke="none" />
        <circle cx={13.392} cy={13.392} r={12.392} />
      </g>
      <path data-name="Line 4" d="M25.839 1v49.678" />
      <path data-name="Line 3" d="M50.678 25.839H1" />
    </g>
  </svg>
);

export default SvgCross;
