import React, { Component } from "react";
import Fade from "react-reveal/Fade"; //https://www.react-reveal.com/docs/
import { Parallax } from "react-scroll-parallax";
import Trava from "../svgs/Trava.js";

import uredjenjeZP from "../imgs/usluge/uredjenjeZP.jpg";
import krajobrazni from "../imgs/usluge/krajobrazni_projekti.png";
import navodnjavanje from "../imgs/usluge/navodnjavanje.png";
import odrzavanje from "../imgs/usluge/odrzavanje.jpg";
import niskogradnja from "../imgs/usluge/niskogradnja.jpg";
import sumarstvo from "../imgs/usluge/sumarstvo.jpg";
import nadzor from "../imgs/usluge/nadzor.jpg";

export default class Usluge extends Component {
  constructor() {
    super();
    this.state = {
      usluge: [
        {
          id: 1,
          naslov: "uređenje zelenih površina",
          opis: "Pružamo usluge uređenja zelenih površina",
          slika: uredjenjeZP,
        },
        {
          id: 2,
          naslov: "krajobrazni projekti",
          opis: "Izrađujemo krajobrazne projekte svih razina složenosti",
          slika: krajobrazni,
        },
        {
          id: 3,
          naslov: "navodnjavanje",
          opis:
            "Nudimo usluge izrade projekta navodnjavanja i postavljanje sustava automatskog navodnjavanja",
          slika: navodnjavanje,
        },
        {
          id: 4,
          naslov: "održavanje",
          opis:
            "Naši obučeni djelatnici pružaju hortikulturne usluge na profesionalnoj razini tijekom cijele godine",
          slika: odrzavanje,
        },
        {
          id: 5,
          naslov: "niskogradnja",
          opis:
            "Izvodimo građevinske radove u području niskogradnje; izgradnja cesta, ulica, trgova i ostalih proizvoljnih objekata.",
          slika: niskogradnja,
        },
        {
          id: 6,
          naslov: "šumarstvo i sječa drva",
          opis: "Izvodimo radove rušenja i uklanjanja opasnih drveća",
          slika: sumarstvo,
        },
        {
          id: 7,
          naslov: "stručni nadzor",
          opis:
            "Pružamo usluge stručnog nadzora pri izvođenju radova na uređenju zelenih površina",
          slika: nadzor,
        },
      ],
    };
  }
  render() {
    return (
      <section className="usluge section-main">
        <Parallax className="parallax-trava" y={[0, 200]}>
          <Trava className="trava" />
        </Parallax>
        <section className="usluge-a">
          <div className="container">
            <div className="usluge-opis">
              <h2>usluge</h2>
              <p>
                Naš tim ima bogato iskustvo u više grana dijelatnosti, izvrsnu
                infrastrukturu, visoku stručnu kompetenciju te kontinuirano
                razvijamo svoje znanje kako bi pratili najmodernije trendove u
                pokrivenim djelatnostima. To nam omogućuje realizaciju i
                najsloženijih zadataka koje naši klijenti stavljaju pred nas.
                Pružamo usluge na širem području Imotske krajine, Makarske,
                Splita i dalje po dogovoru.
              </p>
            </div>
          </div>
        </section>
        <section className="usluge-b">
          <div className="container">
            {this.state.usluge.map((usluga, broj) => (
              <Fade bottom duration={1000} delay={50 * broj} key={broj}>
                <div className="usluga-box">
                  <div>
                    <h3>
                      <span>0{broj + 1} - </span>
                      {usluga.naslov}
                    </h3>
                    <h4>{usluga.opis}</h4>
                  </div>
                  <div>
                    <img src={usluga.slika} alt="" />
                  </div>
                </div>
              </Fade>
            ))}
          </div>
        </section>
      </section>
    );
  }
}
