import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import FsLightbox from "fslightbox-react";

var slike = [];
var nastavak = [];
export class Images extends Component {
  state = {
    images: [],
    count: 5,
    start: 0,
    imaJos: true,
    isVisible: false,
    slide: 0,
  };
  importAll(r) {
    return r.keys().map(r);
  }

  componentDidMount() {
    //const { count, start } = this.state;
    //this.setState({ images: slike.slice(start, count) });
    // console.log(slike);
  }
  showSlide = (slide) => {
    this.setState({
      isVisible: !this.state.isVisible,
      slide: slide,
    });
  };

  ucitajJos = () => {
    const { count, start } = this.state;
    let doSlike;
    this.setState({ start: start + count });

    if (start + count > slike.length) {
      doSlike = slike.length;
      this.setState({ imaJos: false });
    } else {
      doSlike = start + count;
    }

    nastavak = slike.slice(start, doSlike);
    this.setState({
      images: this.state.images.concat(nastavak),
    });
  };
  render() {
    slike = this.importAll(
      require.context("../imgs/sve_slike", false, /\.(png|jpe?g|svg)$/)
    );
    return (
      <div className="galerija">
        <InfiniteScroll
          className="masonry-with-columns "
          dataLength={this.state.images.length}
          next={this.ucitajJos}
          hasMore={this.state.imaJos}
          loader={<h4>Učitavanje...</h4>}
        >
          {this.state.images.map((image, index) => (
            <img
              src={image}
              alt=""
              key={index}
              onClick={() => this.showSlide(index + 1)}
            />
          ))}
        </InfiniteScroll>
        <FsLightbox
          toggler={this.state.isVisible}
          slide={this.state.slide}
          sources={slike}
        />
      </div>
    );
  }
}

export default Images;
